import {
    Alert,
    Chip,
    Divider,
    Grid,
    IconButton,
    Paper,
    Snackbar,
    Stack,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material"
import {styled} from '@mui/material/styles';
import {PoliceAvatar} from "../PoliceAvatar";
import {ProfileResponse} from "../../data/ProfileResponse";
import EditUserForm from "../../components/profile/EditUserForm";
import useUser from "../../hooks/useUser";
import React, {useCallback, useState} from "react";
import {LanguageOutlined, Policy, SettingsOutlined} from "@mui/icons-material";
import {Link, useNavigate} from "react-router-dom";
import {makeApiCall} from "../../hooks/CancellableApiCall";
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import {AlertType} from "../../data/AlertType";

export interface ProfileInfoProps {
    data: ProfileResponse,
    inactivityNotice: boolean
}

export function removeNewlineSpam(data: string): string {
    let cleanedData = ""
    let usedNewLines = 0
    for (let i = 0; i < data.length; i++) {
        if (data[i] === "\n" && data[i + 1] === "\n") {
            continue
        }
        if (usedNewLines >= 15 && data[i] === "\n") {
            continue
        } else if (data[i] === "\n") {
            usedNewLines += 1
        }

        cleanedData += data[i]
    }
    return cleanedData
}

export const AvatarBlock = styled('div')`
        height: 150px;
        display: flex;
    `

export const TextBracket = styled('div')`
        margin-left: 25px;
        display: flex;
        height: 100%;
        overflow: auto;
        justify-content: center;
        flex-direction: column;
        align-content: center;
        span {
            color: "secondary";
            margin-bottom: 7px;
        }
    `

export function ProfileInfo(props: ProfileInfoProps) {
    const userData = useUser()
    const theme = useTheme()
    const navigate = useNavigate()
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const [alertText, setAlertText] = useState("")

    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    let about;
    if (props.data.aboutMe && props.data.aboutMe.trim().length !== 0) {
        about = <Typography variant="body2"
                            style={{whiteSpace: "pre-wrap"}}>{removeNewlineSpam(props.data.aboutMe)}</Typography>
    } else {
        about = <Typography variant="body2">
            <em>
                {`${props.data.rpName} hasn't filled in their 'about me' section yet!`}
            </em>
        </Typography>
    }


    let blacklistedChip = <></>
    if (userData.user?.permissions.includes("records:viewRecords") && (props.data.blacklisted)) {
        blacklistedChip = <Chip sx={{ml: 1}} label="Blacklisted" size="small" color={"error"}/>
    }

    let dischargedChip = <></>
    if (userData.user?.permissions.includes("records:viewRecords") && (props.data.discharged)) {
        dischargedChip = <Chip sx={{ml: 1}} label="Discharged" size="small" color={"primary"}/>
    }

    let suspendedChip = <></>
    if (userData.user?.permissions.includes("records:viewRecords") && (props.data.suspended)) {
        suspendedChip = <Chip sx={{ml: 1}} label="Suspended" size="small" color={"warning"}/>
    }

    let disabledChip = <></>
    if (props.data.enabled === false) {
        disabledChip = <Chip sx={{ml: 1}} label="Account Disabled" size="small" color={"warning"}/>
    }

    let siteAdmin = ((
        props.data.rank?.displayName === "Chief of Department" ||
        props.data.rank?.displayName === "Deputy Chief of Department" ||
        props.data.roles.find((p) => {
            return (p.displayName === "Head of Development Services" || p.displayName === "Senior Developer")
        }) !== undefined)
    )

    let communityManagement = ((
            props.data.roles.find((p) => {
                return (p.displayName === "Community Management")
            }) !== undefined)
    )

    let siteModerator = ((
            props.data.roles.find((p) => {
                return (p.displayName === "Site Moderator")
            }) !== undefined)
    )

    let siteAdminChip
    if (siteAdmin) {
        siteAdminChip = <>
            <Tooltip
                title="Site Administrators have full permissions on the site and should be contacted if there are any issues"
                arrow>
                <Chip label="Site Administrator" size="small" color={"error"}/>
            </Tooltip>
        </>
    }

    let communityManagementChip
    if (communityManagement) {
        communityManagementChip = <>
            <Tooltip
                title="Community Management has full permissions on the site, however they should NOT be contacted, they hold these permissions purely to monitor the community"
                arrow>
                <Chip label="Community Management" size="small" sx={{backgroundColor: "teal", color: "white"}}/>
            </Tooltip>
        </>
    }

    let siteModeratorChip
    if (siteModerator) {
        siteModeratorChip = <>
            <Tooltip
                title="A member of sever administration with some level of moderation powers on PLPD Onlilne"
                arrow>
                <Chip label="Site Moderator" size="small" sx={{backgroundColor: "deeppink", color: "white"}}/>
            </Tooltip>
        </>
    }

    let editProfileButton = <></>
    if (userData.user?.communityId === props.data.communityId) {
        editProfileButton = <IconButton
            size="small"
            color="primary"
            component={Link}
            to={"/profile/edit"}
        >
            <SettingsOutlined/>
        </IconButton>
    }

    let viewRecordsButton = <></>
    if (userData.user?.permissions.includes("records:viewRecords") && userData.user.communityId !== props.data.communityId) {
        viewRecordsButton = <IconButton
            size="small"
            color="error"
            component={Link}
            to={`/record-search?target=${props.data.communityId}`}
        >
            <Policy/>
        </IconButton>
    }

    return <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={6} xl={4}>
            <Paper variant={"outlined"} sx={{padding: '15px'}}>
                <AvatarBlock>
                    <PoliceAvatar
                        online={props.data.online}
                        avatarUrl={props.data.fullAvatarLink}
                        color={siteAdmin ? "ff0000" : communityManagement ? "008080" : siteModerator ? "AA336A" : props.data.rank?.color ?? "white"}
                        userCommunityId={props.data.communityId}
                        dimensions={150}
                    ></PoliceAvatar>
                    <TextBracket>
                        <Typography variant="h5" color="text.primary">{props.data.nick}</Typography>
                        <span><i className={`rank timeline-rank ${props.data.rank?.iconName}`}/> {props.data.rank?.displayName}</span>
                        <div>
                            <Tooltip title="Forum Profile">
                                <IconButton component={Link}
                                            to={`https://api.perpheads.com/v2/profile/${props.data.communityId}`}
                                            size="small" color="info"><LanguageOutlined/></IconButton>
                            </Tooltip>
                            <Tooltip title="Edit Profile">
                                {editProfileButton}
                            </Tooltip>
                            <Tooltip title="Records">
                                {viewRecordsButton}
                            </Tooltip>
                            {props.data.communityId === userData.user?.communityId ? <Tooltip title="Sync Profile">
                                <IconButton onClick={() => {
                                    makeApiCall({
                                        url: `/api/user/${props.data.communityId}/sync`,
                                        method: "PATCH",
                                        body: {},
                                        onLoadedCallback: () => {
                                            navigate("/")
                                        },
                                        onError: () => {
                                            setAlert("error", "You can only do this once a day")
                                        }
                                    })
                                }}><CloudSyncIcon color={"info"}/></IconButton>
                            </Tooltip> : <></>}
                            {userData.user?.permissions.includes("admin:user") ?
                                <EditUserForm userApiResponse={userData} user={props.data}/> : <></>
                            }
                        </div>
                    </TextBracket>
                </AvatarBlock>
            </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={6} xl={8}>
            <Paper variant={"outlined"}
                   sx={{padding: "15px", height: "181px", overflow: "auto", wordWrap: "break-word"}}>
                <Typography variant="h6" gutterBottom component="span">
                    About Me:
                    <Stack direction={"row"} sx={{float: "right"}}>
                        {siteAdminChip}
                        {communityManagementChip}
                        {siteModeratorChip}
                        {disabledChip}
                        {blacklistedChip}
                        {dischargedChip}
                        {suspendedChip}
                        {props.inactivityNotice ? <Chip label="Inactivity Notice" size="small" color={"info"}/> : <></>}
                    </Stack>
                </Typography>
                <Divider/>
                <Typography component="span" variant="body1" sx={{pt: 1}} color={theme.palette.text.secondary}>
                    {about}
                </Typography>
            </Paper>
        </Grid>
        <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
            <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                {alertText}
            </Alert>
        </Snackbar>
    </Grid>
}