import React, {useRef, useState} from "react";
import {
    Button,
    Paper,
    Stack,
    Typography,
    Alert, FormControlLabel, Checkbox
} from "@mui/material";
import {LexicalEditor} from "lexical";
import {TextEditor} from "../../components/editor/TextEditor";
import {UserSelector} from "../../components/UserSelector";
import {UserResponse} from "../../data/UserResponse";
import {makeApiCall} from "../../hooks/CancellableApiCall";
import {$generateHtmlFromNodes} from '@lexical/html';
import {Link as RouterLink, useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {DatePicker} from "@mui/x-date-pickers";
import {padDateElement} from "../../utils";
import {ConfirmationDialog} from "../../components/ConfirmationDialog";

const initialEditorContent = "<p class=\"editor-paragraph\" dir=\"ltr\"><b><strong class=\"editor-text-bold\" style=\"white-space: pre-wrap;\">What happened:</strong></b><span style=\"white-space: pre-wrap;\">&nbsp;</span><br><br><b><strong class=\"editor-text-bold\" style=\"white-space: pre-wrap;\">What the officer did wrong:</strong></b><span style=\"white-space: pre-wrap;\">&nbsp;</span><br><br><b><strong class=\"editor-text-bold\" style=\"white-space: pre-wrap;\">Witnesses:</strong></b><span style=\"white-space: pre-wrap;\">&nbsp;</span><br><br><b><strong class=\"editor-text-bold\" style=\"white-space: pre-wrap;\">Evidence:</strong></b><br><br><b><strong class=\"editor-text-bold\" style=\"white-space: pre-wrap;\">Location:</strong></b>"

export const InternalAffairsNewComplaintPage = () => {
    const [user, setUser] = useState<UserResponse | null>(null)
    const editorRef = useRef<LexicalEditor>(null)
    const [date, setDate] = useState(dayjs())
    const [anonymous, setAnonymous] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const navigate = useNavigate()

    let complaintTarget

    if (user === null) {
        complaintTarget = <Paper variant={"outlined"} style={{
            padding: "16px"
        }}>
            <Typography variant="subtitle1">Complaint Target</Typography>
            <UserSelector onUserSelected={setUser} user={user} extendedUserInformation={true}/>
        </Paper>
    } else {
        complaintTarget = <Paper variant="outlined" style={{
            padding: "16px"
        }}>
            <Typography variant="subtitle1">Complaint Target</Typography>
            <Typography variant="subtitle1">
            <UserSelector user={user} onUserSelected={setUser} extendedUserInformation={true}/>
            </Typography>
        </Paper>
    }

    const handleConfirmClose = (confirm: boolean) => {
        setOpenConfirm(false)
        setAnonymous(confirm)
    }

    const handleBoxClick = () => {
        if (anonymous) {
            setAnonymous(false)
        } else {
            setOpenConfirm(true)
        }
    }

    return (
        <Stack spacing={1}>
            <Alert variant={"outlined"} severity="warning">All complaints require evidence to kickstart the investigation. If you don’t provide evidence initially, you'll have 48 hours to submit it. Otherwise, the complaint will be closed</Alert>
            <Alert variant={"outlined"} severity="info">To submit a complaint anonymously please make sure to check the box under the complaint details section</Alert>
            <Typography sx={{pt:2}} variant="h6" gutterBottom>Complaint Creation</Typography>
            {complaintTarget}
            <Paper variant="outlined" style={{
                marginTop: "16px",
                padding: "16px"
            }}>
                <Typography variant="subtitle1">Complaint Details</Typography>
                <DialogContent>
                    <DialogContentText>Date of the Incident</DialogContentText>
                    <DatePicker
                        defaultValue={date}
                        views={['year', 'month', 'day']}
                        onChange={(event) => {setDate(event!!)}}
                    />
                </DialogContent>
                <DialogContent>
                    <DialogContentText>Details of the Incident</DialogContentText>
                    <TextEditor ref={editorRef} initialContent={initialEditorContent}/>
                    <FormControlLabel label={"Submit Complaint Anonymously"} control={
                        <Checkbox onChange={(_, checked) => handleBoxClick()}
                                  checked={anonymous}/>
                    }/>
                </DialogContent>
                <Button disabled={user === null} color="success" onClick={() => {
                    if (editorRef.current) {
                        editorRef.current.getEditorState().read(() => {
                            makeApiCall({
                                url: `/api/internal-affairs/`,
                                method: "POST",
                                body: {
                                    defendantCommunityId: user?.communityId,
                                    content: $generateHtmlFromNodes(editorRef.current!),
                                    date: date.year().toString() + "-" + padDateElement(date.month() + 1) + "-" + padDateElement(date.date()),
                                    anonymous: anonymous
                                },
                                onLoadedCallback: () => {
                                    navigate("/complaints")
                                },
                                onError: () => {

                                }
                            })
                        })
                    }
                }}>Submit Complaint</Button>
                <Button component={RouterLink} to={`/complaints`} color="warning">Return</Button>
            </Paper>
            <ConfirmationDialog title={"Anonymous Complaint PSA"} description={
                "Once you submit this complaint you cannot change it back. " +
                "When doing this you should go out of your way to ensure that you do not by mistake show who you are, common ways to do this are: " +
                "ensuring that all evidence is uploaded to a third party service (YT etc) on a account that cannot be linked back to you, and " +
                "do not give identifying information to anyone even the investigator, this includes RP name, badge number, SteamID, etc. " +
                "Doing this will hide all the information about you on this website, but do not forget that it will not do anything for any third party site."
            } open={openConfirm} onClose={handleConfirmClose} />
        </Stack>
    )
}