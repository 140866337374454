
interface RecordType {
    key: string
    displayName: string
}

const recordTypes: RecordType[] = [
    { key: "blacklist", displayName: "Blacklist" },
    { key: "suspension", displayName: "Suspension" },
    { key: "reprimand", displayName: "Reprimand" },
    { key: "written_warning", displayName: "Written Warning" },
    { key: "demotion_to_probationary_officer", displayName: "Demotion to Probationary Officer" },
    { key: "demotion", displayName: "Demotion" },
    { key: "division_removal", displayName: "Division Removal" },
    { key: "discharge", displayName: "Discharge" },
    { key: "no_action", displayName: "No Action" },
    { key: "note", displayName: "Record Note" },
    { key: "comment_card", displayName: "Comment Card" },
    { key: "activity_warning", displayName: "Activity Warning" }
]

export const RecordTypes: Map<string, RecordType> = new Map(recordTypes.map(i => [i.key, i]))
