import * as React from 'react';
import {useCallback, useState} from 'react';
import {Button, IconButton, Tooltip} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Alert, Input, Snackbar} from "@mui/material";
import {makeApiCall} from "../../hooks/CancellableApiCall";
import {Edit} from "@mui/icons-material";
import {ProfileResponse} from "../../data/ProfileResponse";
import {UserContextProps} from "../../hooks/useUser";

interface EditUserFormProps {
    user: ProfileResponse
    userApiResponse: UserContextProps
}

type alertType = "success" | "error" | "info"

export default function EditUserForm(props: EditUserFormProps) {
    const [open, setOpen] = useState(false);
    const [nick, setNick] = useState(props.user.nick)
    const [badgeNumber, setBadgeNumber] = useState(props.user.badgeNumber)
    const [aboutMe, setAboutMe] = useState(props.user.aboutMe)
    const [baseProfileUrl, setBaseProfileUrl] = useState(props.user.avatarLink)
    const [medProfileUrl, setMedProfileUrl] = useState(props.user.mediumAvatarLink)
    const [fullProfileUrl, setFullProfileUrl] = useState(props.user.fullAvatarLink)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<alertType>("success")
    const [alertText, setAlertText] = useState("")

    const setAlert = useCallback((type: alertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Tooltip title="Edit Account">
            <IconButton onClick={() => setOpen(true)} size="small" color="warning"><Edit/></IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Editing {props.user.nick}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Username
                    </DialogContentText>
                    <Input id={"nick"} value={nick} size="small" sx={{width: 500}}
                           onChange={(event) => setNick(event.target.value)}
                    ></Input>
                    <DialogContentText>
                        Badge Number
                    </DialogContentText>
                    <Input id={"badge"} type={"number"} value={badgeNumber} size="small" sx={{width: 500}}
                           onChange={(event) => setBadgeNumber(Number(event.target.value))}
                    ></Input>
                   <DialogContentText>
                        About Me
                    </DialogContentText>
                    <Input fullWidth multiline rows={4} placeholder="Tell us about yourself..." value={aboutMe}
                           onChange={(event) => setAboutMe(event.target.value)}
                    ></Input>
                </DialogContent>
                <DialogContent>
                    <DialogContentText>
                        Base Avatar URL
                    </DialogContentText>
                    <Input id={"p1"} value={baseProfileUrl} size="small" sx={{width: 500}}
                           onChange={(event) => setBaseProfileUrl(event.target.value)}
                    ></Input>
                    <DialogContentText>
                        Medium Avatar URL
                    </DialogContentText>
                    <Input id={"p2"} value={medProfileUrl} size="small" sx={{width: 500}}
                           onChange={(event) => setMedProfileUrl(event.target.value)}
                    ></Input>
                    <DialogContentText>
                        Full Avatar URL
                    </DialogContentText>
                    <Input id={"p3"} value={fullProfileUrl} size="small" sx={{width: 500}}
                           onChange={(event) => setFullProfileUrl(event.target.value)}
                    ></Input>
                </DialogContent>
                <DialogActions>
                    <Button color={"error"} onClick={handleClose}>Cancel</Button>
                    <Button color={"success"} onClick={() =>
                        makeApiCall({
                            url: `/api/user/${props.user.communityId}`,
                            method: "PATCH",
                            body: {
                                nick: nick,
                                badgeNumber: badgeNumber,
                                aboutMe: aboutMe,
                                baseAvatarLink: baseProfileUrl,
                                mediumAvatarLink: medProfileUrl,
                                fullAvatarLink: fullProfileUrl
                            },
                            onLoadedCallback: () => {
                                props.userApiResponse.refresh()
                                handleClose()
                            },
                            onError: () => {
                                setAlert("error", "Profile failed to edit, check badge and nick for conflicts")
                            }
                        })
                    }>Edit</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
                <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                    {alertText}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}