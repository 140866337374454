import React, {useState} from "react";
import {
    Paper,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import useApiCall from "../../hooks/CancellableApiCall";
import {LoadingContent} from "../../components/LoadingContent";
import {LimitedActivityAuditResponse} from "../../data/activity-auditing/LimitedActivityAuditResponse";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {divisionDisplayNameToIdentifier, formatDate} from "../../utils";
import Button from "@mui/material/Button";
import {PoliceUser} from "../../components/PoliceUser";

interface ActivityAuditOverviewPageContentProps {
    activity: { [division: string]: LimitedActivityAuditResponse[] }
}

interface LimitedAuditTableProps {
    divisionEmployees: LimitedActivityAuditResponse[]
}

function LimitedAuditTableEmployees(props: LimitedAuditTableProps) {
    return <TableContainer component={Paper}>
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Nick</TableCell>
                    <TableCell>Total Activity this Month</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.divisionEmployees?.map((employee, idx) => {
                    return <TableRow key={idx}>
                        <TableCell>
                            <PoliceUser
                                user={{nick: employee.nick, communityId: employee.communityId.toString()}}/></TableCell>
                        <TableCell>{employee.totalMonthlyPoints}</TableCell>
                        <TableCell>
                            <Tooltip title={"UI is still being worked on, to be clear the tracking is FULLY functional, if the number is low, they have done nothing, if they are not here at all they have done literally nothing"}>
                                <Button size="small" color={"info"}>
                                    Detailed Log
                                </Button>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </TableContainer>
}

function LimitedAuditTable(props: LimitedAuditTableProps) {
    const [selectedTab, setSelectedTab] = useState(props.divisionEmployees[0].date)

    let filteredDates: string[] = []
    props.divisionEmployees.forEach(employee => {
        if (!filteredDates.includes(employee.date)) {
            filteredDates.push(employee.date)
        }
    })

    return <TabContext value={selectedTab}>
        <>
            <TabList onChange={(_, newValue) => setSelectedTab(newValue)}>
                {filteredDates.map(date => {
                    return <Tab key={divisionDisplayNameToIdentifier(date)} value={date} label={formatDate(date)}/>
                })}
            </TabList>
            {filteredDates.map(date => {
                return <TabPanel key={divisionDisplayNameToIdentifier(date)} value={date}>
                    <LimitedAuditTableEmployees divisionEmployees={
                        props.divisionEmployees.filter(employee => {
                            return employee.date === date
                        })
                    }/>
                </TabPanel>
            })}
        </>
    </TabContext>
}

function ActivityAuditOverviewPageContent(props: ActivityAuditOverviewPageContentProps) {
    const [selectedTab, setSelectedTab] = useState(divisionDisplayNameToIdentifier(Object.entries(props.activity)[0][0]))

    return <Paper variant={"outlined"}>
        <Stack spacing={1}>
            <TabContext value={selectedTab}>
                <>
                    <TabList onChange={(_, newValue) => setSelectedTab(newValue)}>
                        {Object.entries(props.activity)?.map(([division]) => {
                            return <Tab key={divisionDisplayNameToIdentifier(division)} value={divisionDisplayNameToIdentifier(division)} label={division}/>
                        })}
                    </TabList>
                    {Object.entries(props.activity)?.map(([division, data]) => {
                        return <TabPanel key={divisionDisplayNameToIdentifier(division)} value={divisionDisplayNameToIdentifier(division)}>
                            <LimitedAuditTable divisionEmployees={data}/>
                        </TabPanel>
                    })}
                </>
            </TabContext>
        </Stack>
    </Paper>
}

export const ActivityAuditOverviewPage = () => {
    const activityData = useApiCall<{[division: string]: LimitedActivityAuditResponse[]}>({
        url: "/api/activity/administrative-activity"
    })

    return (
        <LoadingContent isLoading={
            activityData.isLoading || !activityData.data
        }>
            <ActivityAuditOverviewPageContent activity={activityData.data!!}/>
        </LoadingContent>
    )
}