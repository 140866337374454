import React, {useState} from "react";
import {
    Box,
    Button,
    Chip,
    Pagination,
    Paper, Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography, useTheme
} from "@mui/material";
import useApiCall, {makeApiCall} from "../../hooks/CancellableApiCall";
import {LoadingContent} from "../../components/LoadingContent";
import {Link as RouterLink, useParams, useSearchParams} from "react-router-dom";
import {
    ApplicationMakerOverviewPageinationResponse
} from "../../data/applications/ApplicationMakerOverviewPageinationResponse";
import {formatTimestamp} from "../../utils";
import {UserApplicationStatus} from "../../data/applications/UserApplicationStatus";
import {PoliceUser} from "../../components/PoliceUser";
import {ConfirmationDialog} from "../../components/ConfirmationDialog";

interface ApplicationMarkingOverviewPageContentProps {
    applicationId: number
    applicationData: ApplicationMakerOverviewPageinationResponse
    applicationDataRefreshFun: () => void
}

function ApplicationMarkingOverviewPageContent(props: ApplicationMarkingOverviewPageContentProps) {
    const theme = useTheme()
    const [currentApplicationTarget, setCurrentApplicationTarget] = useState(-1)
    const [failConfirm, setFailConfirm] = useState(false)

    const fail = (confirm: boolean) => {
        if (confirm) {
            makeApiCall({
                url: `/api/careers/administrative-fail/${currentApplicationTarget}`,
                method: "PATCH",
                body: {},
                onLoadedCallback: () => {
                    props.applicationDataRefreshFun()
                },
                onError: () => {}
            })
        }
        setFailConfirm(false)
        setCurrentApplicationTarget(-1)
    }

    let additionalAssessments = <Stack direction={"row"}>
        {
            props.applicationData?.assessmentsOnApplication?.map((assessment, idx) => {
                return <Stack direction={"row"} key={idx}>
                    <Button sx={{ml:1}} component={RouterLink}
                            to={`/careers/overview/${props.applicationId}/${assessment.id}?page=1`}
                            color="info">{assessment.title}</Button>
                    <Typography variant={"subtitle2"} color={theme.palette.text.secondary}>({assessment.openUserAssessmentsCount})</Typography>
                </Stack>
            })
        }
        <Stack direction={"row"}>
            <Button sx={{ml:1}} component={RouterLink}
                    to={`/careers/overview/${props.applicationId}/passed?page=1`}
                    color="success">Accepted</Button>
            <Typography variant={"subtitle2"} color={theme.palette.text.secondary}>({props.applicationData.passedUserApplicationsCount})</Typography>
        </Stack>
        <Stack direction={"row"}>
            <Button sx={{ml:1}} component={RouterLink}
                    to={`/careers/overview/${props.applicationId}/failed?page=1`}
                    color="error">Denied</Button>
            <Typography variant={"subtitle2"} color={theme.palette.text.secondary}>({props.applicationData.failedUserApplicationsCount})</Typography>
        </Stack>
    </Stack>
    return <Paper variant={"outlined"} style={{
        marginTop: "16px",
        padding: "16px"
    }}>
        <Box sx={{overflowX: "auto"}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    props.applicationData?.applications?.map((applicant, idx) => {
                            let applicantStatus = UserApplicationStatus.get(applicant.applicationState)
                            let statusColor
                            if (applicantStatus?.displayName === "Failed" || applicantStatus?.displayName === "Withdrawn" || applicantStatus?.displayName === "Administrative Fail") {
                                statusColor = "red"
                            } else if (applicantStatus?.displayName === "Passed") {
                                statusColor = "green"
                            } else if (applicantStatus?.displayName === "In Progress") {
                                statusColor = "#9333a4"
                            } else {
                                statusColor = "#f8ac59"
                            }

                            return <TableRow key={idx}>
                                <TableCell> <PoliceUser linkProps={{variant: "subtitle1"}}
                                                        user={{nick: applicant.nick, communityId: applicant.communityId}}/> </TableCell>
                                <TableCell> {formatTimestamp(applicant.lastUpdateDate)} </TableCell>
                                <TableCell> <Chip color={"primary"} style={{backgroundColor: statusColor, height: "20px"}}
                                                  label={applicantStatus?.displayName}/> </TableCell>
                                <TableCell>
                                    <Stack direction={"row"}>
                                        <Button component={RouterLink}
                                                to={`/careers/mark/${props.applicationId}/${applicant.communityId}`}
                                                color="info">View Assessments</Button>
                                        {applicantStatus?.displayName !== "Passed" && applicantStatus?.displayName !== "Failed" && applicantStatus?.displayName !== "Withdrawn" && applicantStatus?.displayName !== "Administrative Fail" ? <>
                                            <Button onClick={() => {
                                                setFailConfirm(true)
                                                setCurrentApplicationTarget(applicant.userAssessmentId)
                                            }} color="error">Fail</Button>
                                        </> :  <>
                                            <Button disabled={true} color="error">Fail</Button>
                                        </>}
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        }
                    )
                }
            </TableBody>
        </Table>
        </Box>
        <Box sx={{pt:2}}>
            {additionalAssessments}
        </Box>
        <ConfirmationDialog title={`Fail Application`} description={"This will fail the applicant, this cannot be undone"} open={failConfirm} onClose={fail} />
    </Paper>
}

export const ApplicationMarkingOverviewPage = () => {
    const params = useParams()
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()
    const page = parseInt(searchParams.get("page") ?? "1")
    const applicants = useApiCall<ApplicationMakerOverviewPageinationResponse>({
        url: `/api/careers/${params.stage === "passed" || params.stage === "failed" ? "final-results/" : ""}${params.careerId}/${params.stage}/${page}`
    })

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        searchParams.set("page", value.toString())
        setSearchParams(searchParams)
    }

    const totalPages = applicants.data?.pages ?? 1

    return <LoadingContent
        isLoading={(applicants.isLoading || !applicants.data)}>
        <ApplicationMarkingOverviewPageContent
            applicationId={Number(params.careerId) ?? 0}
            applicationData={applicants.data!!}
            applicationDataRefreshFun={applicants.refresh}
        />
        <Box sx={{justifyContent: "center", display: "flex", mt: 2, width: "100%"}}>
            <Pagination color={'secondary'} count={totalPages} page={page} style={{padding: 8}}
                        onChange={handleChange}/>
        </Box>
    </LoadingContent>
}